var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('HelpCard',{staticClass:"mt-2",attrs:{"caption":"Показание препарата","outlined":""}},[(_vm.about.error)?_c('v-alert',{attrs:{"type":"error","outlined":"","dense":""}},[_vm._v(" "+_vm._s(_vm.about.error)+" ")]):_c('v-card',{attrs:{"loading":_vm.about.loading,"flat":""}},[(_vm.about.data)?_c('v-card-text',{staticClass:"pt-0"},[(_vm.about.data.about_ready)?_c('v-textarea',{attrs:{"value":_vm.about.data.condition,"readonly":"","auto-grow":"","rows":"2"}}):_c('v-alert',{attrs:{"type":"warning","outlined":"","dense":""}},[_vm._v(" Ожидание сводной версии \"Информации о препарате\" (см. показание "),_c('b',[_vm._v("ГРЛС")]),_vm._v(" / "),_c('b',[_vm._v("ЕАЭС")]),_vm._v(") ")])],1):_vm._e()],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-tabs',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.getTabNames),function(tabName,tabIndex){return _c('v-tab',{key:tabIndex,staticClass:"font-weight-bold"},[_vm._v(_vm._s(tabName.name)+" ")])}),1),_c('v-tabs-items',{model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},_vm._l((_vm.getTabNames),function(tabName,tabIndex){return _c('v-tab-item',{key:tabIndex},[_c('v-card',{staticClass:"mt-2",attrs:{"shaped":"","outlined":""}},[_c('v-card-title',{staticClass:"pb-0 text-button"},[_vm._v("Выбор заключения")]),_c('v-card-text',[_c('CompareRow',{attrs:{"root":"data","root-index":_vm.activeTab.toString(),"tag":"data","hideUseBtn":"","editable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var position = ref.position;
return [_c('v-select',{attrs:{"items":_vm.getTitles(value),"item-text":"name","item-value":"index","hide-details":""},scopedSlots:_vm._u([(_vm.getTitles(value).length > 1)?{key:"append-outer",fn:function(){return [_c('v-chip',{staticClass:"my-0",attrs:{"small":""}},[_vm._v(_vm._s(_vm.selectedData[_vm.activeTab][position] + 1)+" из "+_vm._s(_vm.getTitles(value).length))])]},proxy:true}:null],null,true),model:{value:(_vm.selectedData[_vm.activeTab][position]),callback:function ($$v) {_vm.$set(_vm.selectedData[_vm.activeTab], position, $$v)},expression:"selectedData[activeTab][position]"}})]}}],null,true)}),_c('CompareRow',{attrs:{"root":("data[" + _vm.activeTab + "].data"),"root-index":_vm.selectedData[_vm.activeTab],"append":"","remove":""},on:{"append":_vm.updateAppend,"remove":_vm.removeItem},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [(!value)?_c('v-alert',{attrs:{"type":"warning","border":"top","colored-border":"","elevation":"1"}},[_vm._v("Нет данных")]):_vm._e()]}}],null,true)})],1)],1)],1)}),1),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Оригинал")]),_c('v-card-text',{staticClass:"pb-0"},[_c('CompareRow',{attrs:{"root":("data[" + _vm.activeTab + "].data"),"root-index":_vm.selectedData[_vm.activeTab],"tag":"original"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
var data = ref.data;
return [(data)?_c('AutoGrowTextEditor',{attrs:{"value":value},on:{"change":change,"input":change}}):_vm._e()]}}])})],1)],1),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Перевод")]),_c('v-card-text',{staticClass:"pb-0"},[_c('CompareRow',{attrs:{"root":("data[" + _vm.activeTab + "].data"),"root-index":_vm.selectedData[_vm.activeTab],"tag":"translate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
var data = ref.data;
return [(data)?_c('AutoGrowTextEditor',{attrs:{"value":value},on:{"change":change,"input":change}}):_vm._e()]}}])})],1)],1),_c('v-card',{staticClass:"mt-2",attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("Ссылка")]),_c('v-card-text',{staticClass:"pb-0"},[_c('CompareRow',{attrs:{"root":("data[" + _vm.activeTab + "].data"),"root-index":_vm.selectedData[_vm.activeTab],"tag":"link"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
var change = ref.change;
var data = ref.data;
var isResult = ref.isResult;
return [(data)?_c('ArrangeLink',{attrs:{"value":value,"no-validate":!isResult},on:{"input":change}}):_vm._e()]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }