<template>
  <div v-bind="$attrs" class="d-flex">
    <div class="text--disabled cursor-help ml-1">
      <v-icon small lef t class="mb-1" left>mdi-lightbulb-on-outline</v-icon>
      <span v-if="label">{{ label }}</span
      ><slot v-else />
    </div>
  </div>
</template>

<style scoped>
.cursor-help {
  cursor: help;
}
</style>

<script>
export default {
  inheritAttrs: false,
  props: {
    label: { type: String },
  },
}
</script>
