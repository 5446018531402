<template>
  <VersionComparator v-slot="{ data_ready }">
    <CompareAgencies :dataReady="data_ready" />
  </VersionComparator>
</template>

<script>
import VersionComparator from '@/components/compare/VersionComparator'
import CompareAgencies from '@/components/expAgency/CompareAgencies'

export default {
  components: {
    VersionComparator,
    CompareAgencies,
  },
  data: () => ({}),
}
</script>
