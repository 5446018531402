<template>
  <v-textarea v-bind="$attrs" v-on="$listeners" class="growup" auto-grow />
</template>

<script>
export default {
  name: 'Auto-Grow-Text-Editor',
  inheritAttrs: false,
  data() {
    return {}
  },
}
</script>

<style scoped>
.growup {
  height: 100% !important;
}
.growup >>> .v-input__control {
  height: 100% !important;
}
.growup >>> .v-input__slot {
  height: 100% !important;
}
.growup >>> .v-text-field__slot {
  height: 100% !important;
}
</style>
