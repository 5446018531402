import { SUB_VERSION_MIGRATION, SUB_EX_AGE } from '@/store/const/expertise'
import { copyObject } from '@/lib/objects'

const EX_AGENCIES_TEMPLATE_VERSION = 3

export const EX_AGENCIES_NICE = 0
export const EX_AGENCIES_IQWIG = 1
export const EX_AGENCIES_CADTH = 2
// агентства
export const EX_AGENCIES_DEFAULT = {
  data: [
    {
      name: 'NICE',
      data: [
        {
          original: '',
          translate: '',
          link: '',
        },
      ],
    },
    {
      name: 'IQWIG',
      data: [
        {
          original: '',
          translate: '',
          link: '',
        },
      ],
    },
    {
      name: 'CDA',
      data: [
        {
          original: '',
          translate: '',
          link: '',
        },
      ],
    },
  ],
}

// Установили текущую версию данных
SUB_VERSION_MIGRATION[SUB_EX_AGE].v = EX_AGENCIES_TEMPLATE_VERSION
// Добавили миграции для перехода на версию 2
SUB_VERSION_MIGRATION[SUB_EX_AGE][1] = function (prev) {
  const data = copyObject(EX_AGENCIES_DEFAULT.data)
  data.forEach((agency, index) => {
    agency.data = prev[index]?.data ?? agency.data
  })
  return { data, v: 2 }
}
// Добавили миграции для перехода на версию 3
SUB_VERSION_MIGRATION[SUB_EX_AGE][2] = function (prev) {
  prev.data.forEach(item => {
    if (item.name === 'CADTH') item.name = 'CDA'
  })
  return { data: prev.data, v: 3 }
}
