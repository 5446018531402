<template>
  <div>
    <v-text-field
      v-if="!link"
      v-bind="$attrs"
      v-on="$listeners"
      :label="label"
      v-model="linkText"
      @change="doChange"
      :readonly="readonly"
      :rules="noValidate ? [] : [rules_link]"
      maxlength="255"
    />
    <span v-else class="v-application"
      >{{ label }}:
      <a
        v-if="linkText"
        v-bind="$attrs"
        v-on="$listeners"
        :href="href"
        class="ml-2 link text-truncate"
        @click.prevent="onClickLink"
        >{{ getLinkText(linkText) }}</a
      >
    </span>
  </div>
</template>

<script>
export default {
  name: 'ArrangeLink',
  inheritAttrs: false,

  data: () => ({
    linkText: '',
  }),
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      required: false,
      default: 'Ссылка',
    },
    // По-умолчанию режим редактора, иначе как ссылка
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    // переход по ссылке если не в режиме редактора
    link: {
      type: Boolean,
      required: false,
      default: false,
    },
    // TODO
    button: {
      type: Boolean,
      required: false,
      default: false,
    },
    // По-умолчанию переход по ссылке в новом окне
    newtab: {
      type: Boolean,
      required: false,
      default: true,
    },
    // По-умолчанию к сыылке добавляется префикс https,
    // флаг меняет префикс на http
    http: {
      type: Boolean,
      required: false,
      default: false,
    },
    // По0умолчаниб валидация включена
    // для отключения :noValidate="true"
    noValidate: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  created() {
    this.init()
  },

  watch: {
    value: 'init',
  },

  computed: {
    href() {
      try {
        const url = new URL(this.getLinkText(this.linkText))
        return url.href
      } catch (_) {
        return this.linkText
      }
    },
  },

  methods: {
    init() {
      this.linkText = this.value
    },

    onClickLink() {
      if (this.readonly && !this.link) return
      // this.$router.absUrl(this.linkText, this.isOpenInNewTab)
      if (this.newtab) {
        window.open(this.getLinkText(this.linkText), '_blank')
      } else {
        window.location.href = this.linkText
      }
    },

    doChange(v) {
      if (!this.readonly) this.$emit('change', v)
    },

    getLinkText(value = '') {
      let link = value.trim()
      if (link.startsWith('http://') || link.startsWith('https://')) {
        return link
      }

      link = link.replace(/(^\w+:|^)\/\//, '')
      return this.http ? 'http://' + link : 'https://' + link
    },

    rules_link(v) {
      if (this.noValidate || !v) return true

      const r = new RegExp(
        '^((http|https)?:\\/\\/)?' + // validate protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
          '(\\?[;&a-z\\S\\d%_.~+=-]*)?' + // validate query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ) // validate fragment locator
      const linkText = this.getLinkText(v)

      return (this.isURL(linkText) && !!r.test(linkText)) || 'Ссылка не валидна'
    },

    isURL(str) {
      try {
        const url = new URL(str)
        return url.protocol === 'http:' || url.protocol === 'https:'
      } catch (_) {
        return false
      }
    },
  },
}
</script>

<style>
.link {
  cursor: pointer;
  text-decoration: underline;
}
</style>
