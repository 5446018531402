<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <!-- справочная информация -->
        <HelpCard class="mt-2" caption="Показание препарата" outlined>
          <v-alert v-if="about.error" type="error" outlined dense>
            {{ about.error }}
          </v-alert>
          <v-card v-else :loading="about.loading" flat>
            <v-card-text v-if="about.data" class="pt-0">
              <v-textarea
                v-if="about.data.about_ready"
                :value="about.data.condition"
                readonly
                auto-grow
                rows="2"
              />
              <v-alert v-else type="warning" outlined dense>
                Ожидание сводной версии "Информации о препарате" (см. показание
                <b>ГРЛС</b> / <b>ЕАЭС</b>)
              </v-alert>
            </v-card-text>
          </v-card>
        </HelpCard>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <!-- выбор агенств NICE / IQWIG / CADTH -->
        <v-tabs v-model="activeTab">
          <v-tab
            v-for="(tabName, tabIndex) in getTabNames"
            :key="tabIndex"
            class="font-weight-bold"
            >{{ tabName.name }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="activeTab">
          <v-tab-item
            v-for="(tabName, tabIndex) in getTabNames"
            :key="tabIndex"
          >
            <v-card shaped outlined class="mt-2">
              <v-card-title class="pb-0 text-button"
                >Выбор заключения</v-card-title
              >
              <v-card-text>
                <CompareRow
                  root="data"
                  :root-index="activeTab.toString()"
                  tag="data"
                  hideUseBtn
                  editable
                  v-slot="{ value, position }"
                >
                  <v-select
                    v-model="selectedData[activeTab][position]"
                    :items="getTitles(value)"
                    item-text="name"
                    item-value="index"
                    hide-details
                    ><template #append-outer v-if="getTitles(value).length > 1"
                      ><v-chip small class="my-0"
                        >{{ selectedData[activeTab][position] + 1 }} из
                        {{ getTitles(value).length }}</v-chip
                      >
                    </template>
                  </v-select>
                </CompareRow>
                <!-- управление всем блоком выбранного заключения -->
                <CompareRow
                  :root="`data[${activeTab}].data`"
                  :root-index="selectedData[activeTab]"
                  append
                  @append="updateAppend"
                  remove
                  @remove="removeItem"
                  v-slot="{ value }"
                  ><v-alert
                    v-if="!value"
                    type="warning"
                    border="top"
                    colored-border
                    elevation="1"
                    >Нет данных</v-alert
                  ></CompareRow
                >
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>

        <!-- Экспертиза по агенству - Оригинал -->
        <v-card outlined class="mt-2">
          <v-card-title>Оригинал</v-card-title>
          <v-card-text class="pb-0">
            <CompareRow
              :root="`data[${activeTab}].data`"
              :root-index="selectedData[activeTab]"
              tag="original"
              v-slot="{ value, change, data }"
            >
              <AutoGrowTextEditor
                v-if="data"
                @change="change"
                @input="change"
                :value="value"
              />
            </CompareRow>
          </v-card-text>
        </v-card>

        <!-- Экспертиза по агенству - Перевод -->
        <v-card outlined class="mt-2">
          <v-card-title>Перевод</v-card-title>
          <v-card-text class="pb-0">
            <CompareRow
              :root="`data[${activeTab}].data`"
              :root-index="selectedData[activeTab]"
              tag="translate"
              v-slot="{ value, change, data }"
            >
              <AutoGrowTextEditor
                v-if="data"
                @change="change"
                @input="change"
                :value="value"
              />
            </CompareRow>
          </v-card-text>
        </v-card>

        <!-- Экспертиза по агенству - Ссылка -->
        <v-card outlined class="mt-2">
          <v-card-title>Ссылка</v-card-title>
          <v-card-text class="pb-0">
            <CompareRow
              :root="`data[${activeTab}].data`"
              :root-index="selectedData[activeTab]"
              tag="link"
              v-slot="{ value, change, data, isResult }"
            >
              <ArrangeLink
                v-if="data"
                :value="value"
                @input="change"
                :no-validate="!isResult"
              />
            </CompareRow>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { EX_AGENCIES_DEFAULT } from './const'
import CompareRow from '@/components/compare/CompareRow'
import { SUB_EX_ABOUT } from '@/store/const/expertise'
import ArrangeLink from '@/components/editors/ArrangeLink'
import HelpCard from '@/components/HelpCard'
import AutoGrowTextEditor from '@/components/editors/AutoGrowTextEditor'
import { mapActions } from 'vuex'

export default {
  name: 'Compare-Agencies',
  props: {
    dataReady: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CompareRow,
    AutoGrowTextEditor,
    ArrangeLink,
    HelpCard,
  },
  data: () => ({
    activeTab: 0,
    selectedData: [
      [0, 0, 0],
      [0, 0, 0],
      [0, 0, 0],
    ],
    // about
    about: {
      loading: false,
      error: '',
      data: null,
    },
  }),
  computed: {
    getTabNames() {
      return EX_AGENCIES_DEFAULT.data
    },
  },
  created() {
    this.loadAbout()
  },
  methods: {
    ...mapActions(['GET_PLP_REPORT']),
    updateAppend(object, index) {
      this.selectedData[this.activeTab][0] = index
    },
    removeItem(object, index, count) {
      if (count === index) this.selectedData[this.activeTab][0] = count - 1
    },
    getTitles(value) {
      return (
        value?.reduce((items, item, index) => {
          items.push({ index: index, name: `Заключение ${index + 1}` })
          return items
        }, []) || []
      )
    },

    async loadAbout() {
      this.about = { loading: true, data: null, error: '' }
      try {
        const { expertise } = await this.GET_PLP_REPORT([SUB_EX_ABOUT])
        const about_ready = !!expertise?.about
        const condition = expertise?.about?.condition || ''
        this.about.data = {
          about_ready,
          condition,
        }
      } catch (err) {
        this.about.error = err.message + (err.error ? ' ' + err.error : '')
        throw err
      } finally {
        this.about.loading = false
      }
    },
  },
}
</script>
