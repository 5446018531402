<template>
  <v-btn v-if="!pressed" v-bind="$attrs" @click="onConfirmClick"
    ><v-icon
      v-bind="$attrs"
      v-text="iconMain"
      :color="iconColor"
      :small="iconSmall"
      :x-small="iconXSmall" /><slot
  /></v-btn>
  <v-btn
    v-else
    v-bind="$attrs"
    color="warning"
    :title="titleConfirm"
    @mouseleave="pressed = false"
    @click="onClick"
    ><v-icon
      v-bind="$attrs"
      :title="titleConfirm"
      v-text="iconConfirm"
      :color="iconColor" /><slot
  /></v-btn>
</template>

<script>
export default {
  name: 'Btn-With-Confirm',
  props: {
    iconMain: { type: String, default: null },
    iconColor: { type: String, default: null },
    iconConfirm: { type: String, default: 'mdi-exclamation-thick' },
    iconSmall: { type: Boolean, default: false },
    iconXSmall: { type: Boolean, default: false },
    titleConfirm: {
      type: String,
      default: 'Нажмите ещё раз для подтверждения',
    },
  },
  data: () => ({
    pressed: false,
  }),
  methods: {
    onClick(event) {
      this.$emit('click', event)
      this.pressed = false
    },
    onConfirmClick() {
      this.pressed = true
    },
  },
}
</script>

<style></style>
